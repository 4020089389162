import { type DateTime, type DurationLike, Interval } from 'luxon';
import type { DecoratedInstance } from '../types';

export type InstanceIntervalGroup = {
  date: DateTime;
  instances: DecoratedInstance[];
};

export type InstanceFormatGroup = {
  date: DateTime;
  dateGroup: string;
  instances: DecoratedInstance[];
};

const groupInstancesByFormat = (
  instances: DecoratedInstance[],
  format: string
): InstanceFormatGroup[] => {
  return instances.reduce<InstanceFormatGroup[]>((acc, instance) => {
    const dateGroup = instance.startDate.toFormat(format);
    const group = acc.find((group) => group.dateGroup === dateGroup);

    if (!group) {
      acc.push({
        date: instance.startDate.startOf('day'),
        dateGroup,
        instances: [instance],
      });
    } else {
      group.instances.push(instance);
    }

    return acc;
  }, []);
};

export const groupInstancesByDay = (
  instances: DecoratedInstance[]
): InstanceFormatGroup[] => groupInstancesByFormat(instances, 'ccc, LLLL d');

export const groupInstancesByMonth = (
  instances: DecoratedInstance[]
): InstanceFormatGroup[] => groupInstancesByFormat(instances, 'LLLL, yyyy');

export const groupInstancesByInterval = (
  entryInstances: DecoratedInstance[],
  interval: Interval,
  splitBy: DurationLike = { days: 1 }
): InstanceIntervalGroup[] => {
  return interval
    .splitBy(splitBy)
    .map<InstanceIntervalGroup>((dateInterval) => {
      const instances = entryInstances.reduce<DecoratedInstance[]>(
        (instances, instance) => {
          const instanceInterval = Interval.fromDateTimes(
            instance.startDate,
            instance.endDate
          );

          // Prevent duplicate entries on a day. Given a multi-day entry on Mon-Fri, 3am - 3am,
          // when finding instances for Wednesday, two will match: the instance that
          // ends on Wednesday at 3am and the next instance that starts at Wednesday at 3am.
          if (instanceInterval.overlaps(dateInterval)) {
            const exists = instances.find(
              ({ parentId }) => parentId === instance.parentId
            );
            if (!exists) {
              instances.push(instance);
            }
          }

          return instances;
        },
        []
      );

      return {
        date: dateInterval.start,
        instances,
      };
    }, []);
};
